import { GraphQLClient } from "graphql-request";
import { getSdk } from "@/graphql/client";

const client = new GraphQLClient(
  process.env.GRAPHQL_SERVER_URL || process.env.VUE_APP_GRAPHQL_SERVER_URL || "dummy_graphql_server_url",
  {
    headers: {
      'x-giftnavi-apikey': process.env.VUE_APP_GRAPHQL_APIKEY??''
    }
  }
);
export const sdk = getSdk(client);
