import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Color: any;
  Date: any;
  Timestamp: any;
  Url: any;
};

export type CardTemplate = {
  __typename?: 'CardTemplate';
  cardTemplateProperties?: Maybe<Array<Maybe<CardTemplateProperty>>>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  defaultMessage?: Maybe<Scalars['String']>;
  genre?: Maybe<Scalars['String']>;
  guid: Scalars['ID'];
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  templateImageFileName?: Maybe<Scalars['String']>;
  templateImageUrl?: Maybe<Scalars['Url']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CardTemplateProperty = {
  __typename?: 'CardTemplateProperty';
  align?: Maybe<Scalars['String']>;
  bold?: Maybe<Scalars['Int']>;
  cardTemplate: CardTemplate;
  createdAt?: Maybe<Scalars['Timestamp']>;
  fontColor?: Maybe<Scalars['Color']>;
  fontName?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['Int']>;
  guid: Scalars['ID'];
  height?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  positionX?: Maybe<Scalars['Int']>;
  positionY?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  width?: Maybe<Scalars['Int']>;
};

export type CenterMember = {
  __typename?: 'CenterMember';
  accountNumberBC?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  cellPhone?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  email: Scalars['String'];
  guid: Scalars['ID'];
  id: Scalars['String'];
  isVisible?: Maybe<Scalars['Boolean']>;
  memo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nameKana?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  shop?: Maybe<Shop>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};


export type CreateCardTemplateInput = {
  cardTemplateProperties?: Maybe<Array<Maybe<UpsertCardTemplatePropertyInput>>>;
  defaultMessage?: Maybe<Scalars['String']>;
  genre?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  templateImageFileName?: Maybe<Scalars['String']>;
  templateImageUrl?: Maybe<Scalars['Url']>;
};

export type CreateCardTemplatePropertyInput = {
  align?: Maybe<Scalars['String']>;
  bold?: Maybe<Scalars['Int']>;
  cardTemplateGuid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  fontColor?: Maybe<Scalars['Color']>;
  fontName?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  positionX?: Maybe<Scalars['Int']>;
  positionY?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  width?: Maybe<Scalars['Int']>;
};

export type CreateCenterMemberInput = {
  accountNumberBC?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  cellPhone?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  email?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  memo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nameKana?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  shopGuid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateDeliveryCalendarDayInput = {
  date: Scalars['Date'];
  type: DeliveryCalendarDayType;
};

export type CreateInformationInput = {
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['Timestamp']>;
  publicationDate: Scalars['Timestamp'];
  publicationEndDate?: Maybe<Scalars['Timestamp']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreatePickupStoreInput = {
  address: Scalars['String'];
  createdAt?: Maybe<Scalars['Timestamp']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateShopInput = {
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['Timestamp']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};


export type DeleteCardTemplateInput = {
  guid: Scalars['String'];
};

export type DeleteCardTemplatePropertyInput = {
  guid: Scalars['String'];
};

export type DeleteCenterMemberInput = {
  guid: Scalars['String'];
};

export type DeleteDeliveryCalendarDayInput = {
  guid: Scalars['String'];
};

export type DeleteInformationInput = {
  guid: Scalars['String'];
};

export type DeletePickupStoreInput = {
  guid: Scalars['String'];
};

export type DeleteShopInput = {
  guid: Scalars['String'];
};

export type DeliveryCalendarDay = {
  __typename?: 'DeliveryCalendarDay';
  createdAt?: Maybe<Scalars['Timestamp']>;
  date: Scalars['Date'];
  guid: Scalars['ID'];
  type: DeliveryCalendarDayType;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export enum DeliveryCalendarDayType {
  NonBusiness = 'NON_BUSINESS',
  NonFlesh = 'NON_FLESH'
}

export type GuidInput = {
  guid: Scalars['String'];
};

export type Information = {
  __typename?: 'Information';
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['ID'];
  publicationDate: Scalars['Timestamp'];
  publicationEndDate?: Maybe<Scalars['Timestamp']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type InformationFilter = {
  targetDate?: Maybe<Scalars['Timestamp']>;
};

export type InformationOrderByInput = {
  createdAt?: Maybe<Sort>;
  publicationDate?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createCardTemplate?: Maybe<CardTemplate>;
  createCardTemplateProperty?: Maybe<CardTemplateProperty>;
  createCenterMember?: Maybe<CenterMember>;
  createDeliveryCalendarDay: DeliveryCalendarDay;
  createInformation: Information;
  createPickupStore: PickupStore;
  createShop?: Maybe<Shop>;
  deleteCardTemplate?: Maybe<CardTemplate>;
  deleteCardTemplateProperty?: Maybe<CardTemplateProperty>;
  deleteCenterMember?: Maybe<CenterMember>;
  deleteDeliveryCalendarDay: DeliveryCalendarDay;
  deleteInformation: Information;
  deletePickupStore: PickupStore;
  deleteShop?: Maybe<Shop>;
  updateCardTemplate?: Maybe<CardTemplate>;
  updateCardTemplateProperty?: Maybe<CardTemplateProperty>;
  updateCenterMember?: Maybe<CenterMember>;
  updateDeliveryCalendarDay: DeliveryCalendarDay;
  updateInformation: Information;
  updatePickupStore: PickupStore;
  updateShop?: Maybe<Shop>;
};


export type MutationCreateCardTemplateArgs = {
  input?: Maybe<CreateCardTemplateInput>;
};


export type MutationCreateCardTemplatePropertyArgs = {
  input?: Maybe<CreateCardTemplatePropertyInput>;
};


export type MutationCreateCenterMemberArgs = {
  input?: Maybe<CreateCenterMemberInput>;
};


export type MutationCreateDeliveryCalendarDayArgs = {
  input?: Maybe<CreateDeliveryCalendarDayInput>;
};


export type MutationCreateInformationArgs = {
  input?: Maybe<CreateInformationInput>;
};


export type MutationCreatePickupStoreArgs = {
  input?: Maybe<CreatePickupStoreInput>;
};


export type MutationCreateShopArgs = {
  input?: Maybe<CreateShopInput>;
};


export type MutationDeleteCardTemplateArgs = {
  input?: Maybe<DeleteCardTemplateInput>;
};


export type MutationDeleteCardTemplatePropertyArgs = {
  input?: Maybe<DeleteCardTemplatePropertyInput>;
};


export type MutationDeleteCenterMemberArgs = {
  input?: Maybe<DeleteCenterMemberInput>;
};


export type MutationDeleteDeliveryCalendarDayArgs = {
  input?: Maybe<DeleteDeliveryCalendarDayInput>;
};


export type MutationDeleteInformationArgs = {
  input?: Maybe<DeleteInformationInput>;
};


export type MutationDeletePickupStoreArgs = {
  input?: Maybe<DeletePickupStoreInput>;
};


export type MutationDeleteShopArgs = {
  input?: Maybe<DeleteShopInput>;
};


export type MutationUpdateCardTemplateArgs = {
  input?: Maybe<UpdateCardTemplateInput>;
};


export type MutationUpdateCardTemplatePropertyArgs = {
  input?: Maybe<UpdateCardTemplatePropertyInput>;
};


export type MutationUpdateCenterMemberArgs = {
  input?: Maybe<UpdateCenterMemberInput>;
};


export type MutationUpdateDeliveryCalendarDayArgs = {
  input?: Maybe<UpdateDeliveryCalendarDayInput>;
};


export type MutationUpdateInformationArgs = {
  input?: Maybe<UpdateInformationInput>;
};


export type MutationUpdatePickupStoreArgs = {
  input?: Maybe<UpdatePickupStoreInput>;
};


export type MutationUpdateShopArgs = {
  input?: Maybe<UpdateShopInput>;
};

export type PickupStore = {
  __typename?: 'PickupStore';
  address: Scalars['String'];
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['ID'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type Query = {
  __typename?: 'Query';
  cardTemplate?: Maybe<CardTemplate>;
  cardTemplateProperties?: Maybe<Array<Maybe<CardTemplateProperty>>>;
  cardTemplateProperty?: Maybe<CardTemplateProperty>;
  cardTemplates?: Maybe<Array<Maybe<CardTemplate>>>;
  centerMember?: Maybe<CenterMember>;
  centerMembers?: Maybe<Array<Maybe<CenterMember>>>;
  deliveryCalendarDay?: Maybe<DeliveryCalendarDay>;
  deliveryCalendarDays?: Maybe<Array<Maybe<DeliveryCalendarDay>>>;
  information?: Maybe<Information>;
  informations?: Maybe<Array<Maybe<Information>>>;
  nonBusinessDays?: Maybe<Array<Maybe<DeliveryCalendarDay>>>;
  nonFleshDays?: Maybe<Array<Maybe<DeliveryCalendarDay>>>;
  pickupStore?: Maybe<PickupStore>;
  pickupStores?: Maybe<Array<Maybe<PickupStore>>>;
  shop?: Maybe<Shop>;
  shops?: Maybe<Array<Maybe<Shop>>>;
};


export type QueryCardTemplateArgs = {
  guid: Scalars['ID'];
};


export type QueryCardTemplatePropertyArgs = {
  guid: Scalars['ID'];
};


export type QueryCenterMemberArgs = {
  guid?: Maybe<Scalars['String']>;
};


export type QueryDeliveryCalendarDayArgs = {
  guid?: Maybe<Scalars['String']>;
};


export type QueryInformationArgs = {
  guid: Scalars['ID'];
};


export type QueryInformationsArgs = {
  filter?: Maybe<InformationFilter>;
  orderBy?: Maybe<InformationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryPickupStoreArgs = {
  guid?: Maybe<Scalars['String']>;
};


export type QueryShopArgs = {
  uniqueCondition?: Maybe<ShopFindUniqueCondition>;
};

export type Shop = {
  __typename?: 'Shop';
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['ID'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type ShopFindUniqueCondition = {
  code?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['String']>;
};

export enum Sort {
  Asc = 'asc',
  Desc = 'desc'
}


export type UpdateCardTemplateInput = {
  cardTemplateProperties?: Maybe<Array<Maybe<UpsertCardTemplatePropertyInput>>>;
  defaultMessage?: Maybe<Scalars['String']>;
  genre?: Maybe<Scalars['String']>;
  guid: Scalars['String'];
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  templateImageFileName?: Maybe<Scalars['String']>;
  templateImageUrl?: Maybe<Scalars['Url']>;
};

export type UpdateCardTemplatePropertyInput = {
  align?: Maybe<Scalars['String']>;
  bold?: Maybe<Scalars['Int']>;
  cardTemplateGuid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  fontColor?: Maybe<Scalars['Color']>;
  fontName?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['Int']>;
  guid: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  positionX?: Maybe<Scalars['Int']>;
  positionY?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  width?: Maybe<Scalars['Int']>;
};

export type UpdateCenterMemberInput = {
  accountNumberBC?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  cellPhone?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  email?: Maybe<Scalars['String']>;
  guid: Scalars['String'];
  isVisible?: Maybe<Scalars['Boolean']>;
  memo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameKana?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  shopGuid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateDeliveryCalendarDayInput = {
  date: Scalars['Date'];
  guid: Scalars['String'];
  type: DeliveryCalendarDayType;
};

export type UpdateInformationInput = {
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['String'];
  publicationDate: Scalars['Timestamp'];
  publicationEndDate?: Maybe<Scalars['Timestamp']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdatePickupStoreInput = {
  address: Scalars['String'];
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['String'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateShopInput = {
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['String'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpsertCardTemplatePropertyInput = {
  align?: Maybe<Scalars['String']>;
  bold?: Maybe<Scalars['Int']>;
  cardTemplateGuid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  fontColor?: Maybe<Scalars['Color']>;
  fontName?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['Int']>;
  guid?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  positionX?: Maybe<Scalars['Int']>;
  positionY?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  width?: Maybe<Scalars['Int']>;
};


export type CreateCardTemplateMutationVariables = Exact<{
  createCardTemplateInput?: Maybe<CreateCardTemplateInput>;
}>;


export type CreateCardTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createCardTemplate?: Maybe<(
    { __typename?: 'CardTemplate' }
    & Pick<CardTemplate, 'guid' | 'name' | 'genre' | 'templateImageUrl' | 'isVisible' | 'defaultMessage' | 'createdAt' | 'updatedAt'>
    & { cardTemplateProperties?: Maybe<Array<Maybe<(
      { __typename?: 'CardTemplateProperty' }
      & Pick<CardTemplateProperty, 'guid' | 'name' | 'positionX' | 'positionY' | 'width' | 'height' | 'fontName' | 'fontSize' | 'fontColor' | 'createdAt' | 'updatedAt'>
    )>>> }
  )> }
);

export type UpdateCardTemplateMutationVariables = Exact<{
  updateCardTemplateInput?: Maybe<UpdateCardTemplateInput>;
}>;


export type UpdateCardTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateCardTemplate?: Maybe<(
    { __typename?: 'CardTemplate' }
    & Pick<CardTemplate, 'guid' | 'name' | 'genre' | 'templateImageUrl' | 'isVisible' | 'defaultMessage' | 'createdAt' | 'updatedAt'>
    & { cardTemplateProperties?: Maybe<Array<Maybe<(
      { __typename?: 'CardTemplateProperty' }
      & Pick<CardTemplateProperty, 'guid' | 'name' | 'positionX' | 'positionY' | 'width' | 'height' | 'fontName' | 'fontSize' | 'fontColor' | 'createdAt' | 'updatedAt'>
    )>>> }
  )> }
);

export type DeleteCardTemplateMutationVariables = Exact<{
  deleteCardTemplateInput?: Maybe<DeleteCardTemplateInput>;
}>;


export type DeleteCardTemplateMutation = (
  { __typename?: 'Mutation' }
  & { deleteCardTemplate?: Maybe<(
    { __typename?: 'CardTemplate' }
    & Pick<CardTemplate, 'guid' | 'name' | 'genre' | 'templateImageUrl' | 'defaultMessage' | 'isVisible' | 'createdAt' | 'updatedAt'>
  )> }
);

export type CreateCardTemplatePropertyMutationVariables = Exact<{
  createCardTemplatePropertyInput?: Maybe<CreateCardTemplatePropertyInput>;
}>;


export type CreateCardTemplatePropertyMutation = (
  { __typename?: 'Mutation' }
  & { createCardTemplateProperty?: Maybe<(
    { __typename?: 'CardTemplateProperty' }
    & Pick<CardTemplateProperty, 'guid' | 'name' | 'positionX' | 'positionY' | 'width' | 'height' | 'fontName' | 'fontSize' | 'fontColor' | 'bold' | 'align' | 'createdAt' | 'updatedAt'>
    & { cardTemplate: (
      { __typename?: 'CardTemplate' }
      & Pick<CardTemplate, 'guid'>
    ) }
  )> }
);

export type UpdateCardTemplatePropertyMutationVariables = Exact<{
  updateCardTemplatePropertyInput?: Maybe<UpdateCardTemplatePropertyInput>;
}>;


export type UpdateCardTemplatePropertyMutation = (
  { __typename?: 'Mutation' }
  & { updateCardTemplateProperty?: Maybe<(
    { __typename?: 'CardTemplateProperty' }
    & Pick<CardTemplateProperty, 'guid' | 'name' | 'positionX' | 'positionY' | 'width' | 'height' | 'fontName' | 'fontSize' | 'fontColor' | 'bold' | 'align' | 'createdAt' | 'updatedAt'>
    & { cardTemplate: (
      { __typename?: 'CardTemplate' }
      & Pick<CardTemplate, 'guid'>
    ) }
  )> }
);

export type DeleteCardTemplatePropertyMutationVariables = Exact<{
  deleteCardTemplatePropertyInput?: Maybe<DeleteCardTemplatePropertyInput>;
}>;


export type DeleteCardTemplatePropertyMutation = (
  { __typename?: 'Mutation' }
  & { deleteCardTemplateProperty?: Maybe<(
    { __typename?: 'CardTemplateProperty' }
    & Pick<CardTemplateProperty, 'guid' | 'name' | 'positionX' | 'positionY' | 'width' | 'height' | 'fontName' | 'fontSize' | 'fontColor' | 'bold' | 'align' | 'createdAt' | 'updatedAt'>
  )> }
);

export type CreateDeliveryCalendarDayMutationVariables = Exact<{
  createDeliveryCalendarDayInput?: Maybe<CreateDeliveryCalendarDayInput>;
}>;


export type CreateDeliveryCalendarDayMutation = (
  { __typename?: 'Mutation' }
  & { createDeliveryCalendarDay: (
    { __typename?: 'DeliveryCalendarDay' }
    & Pick<DeliveryCalendarDay, 'guid' | 'date' | 'type' | 'createdAt' | 'updatedAt'>
  ) }
);

export type UpdateDeliveryCalendarDayMutationVariables = Exact<{
  updateDeliveryCalendarDayInput?: Maybe<UpdateDeliveryCalendarDayInput>;
}>;


export type UpdateDeliveryCalendarDayMutation = (
  { __typename?: 'Mutation' }
  & { updateDeliveryCalendarDay: (
    { __typename?: 'DeliveryCalendarDay' }
    & Pick<DeliveryCalendarDay, 'guid' | 'date' | 'type' | 'createdAt' | 'updatedAt'>
  ) }
);

export type DeleteDeliveryCalendarDayMutationVariables = Exact<{
  deleteDeliveryCalendarDayInput?: Maybe<DeleteDeliveryCalendarDayInput>;
}>;


export type DeleteDeliveryCalendarDayMutation = (
  { __typename?: 'Mutation' }
  & { deleteDeliveryCalendarDay: (
    { __typename?: 'DeliveryCalendarDay' }
    & Pick<DeliveryCalendarDay, 'guid' | 'date' | 'type' | 'createdAt' | 'updatedAt'>
  ) }
);

export type CreateInformationMutationVariables = Exact<{
  createInformationInput?: Maybe<CreateInformationInput>;
}>;


export type CreateInformationMutation = (
  { __typename?: 'Mutation' }
  & { createInformation: (
    { __typename?: 'Information' }
    & Pick<Information, 'guid' | 'title' | 'content' | 'publicationDate' | 'publicationEndDate' | 'createdAt' | 'updatedAt'>
  ) }
);

export type UpdateInformationMutationVariables = Exact<{
  updateInformationInput?: Maybe<UpdateInformationInput>;
}>;


export type UpdateInformationMutation = (
  { __typename?: 'Mutation' }
  & { updateInformation: (
    { __typename?: 'Information' }
    & Pick<Information, 'guid' | 'title' | 'content' | 'publicationDate' | 'publicationEndDate' | 'createdAt' | 'updatedAt'>
  ) }
);

export type DeleteInformationMutationVariables = Exact<{
  deleteInformationInput?: Maybe<DeleteInformationInput>;
}>;


export type DeleteInformationMutation = (
  { __typename?: 'Mutation' }
  & { deleteInformation: (
    { __typename?: 'Information' }
    & Pick<Information, 'guid' | 'title' | 'content' | 'publicationDate' | 'publicationEndDate' | 'createdAt' | 'updatedAt'>
  ) }
);

export type CreatePickupStoreMutationVariables = Exact<{
  createPickupStoreInput?: Maybe<CreatePickupStoreInput>;
}>;


export type CreatePickupStoreMutation = (
  { __typename?: 'Mutation' }
  & { createPickupStore: (
    { __typename?: 'PickupStore' }
    & Pick<PickupStore, 'guid' | 'name' | 'address' | 'createdAt' | 'updatedAt'>
  ) }
);

export type UpdatePickupStoreMutationVariables = Exact<{
  updatePickupStoreInput?: Maybe<UpdatePickupStoreInput>;
}>;


export type UpdatePickupStoreMutation = (
  { __typename?: 'Mutation' }
  & { updatePickupStore: (
    { __typename?: 'PickupStore' }
    & Pick<PickupStore, 'guid' | 'name' | 'address' | 'createdAt' | 'updatedAt'>
  ) }
);

export type DeletePickupStoreMutationVariables = Exact<{
  deletePickupStoreInput?: Maybe<DeletePickupStoreInput>;
}>;


export type DeletePickupStoreMutation = (
  { __typename?: 'Mutation' }
  & { deletePickupStore: (
    { __typename?: 'PickupStore' }
    & Pick<PickupStore, 'guid' | 'name' | 'address' | 'createdAt' | 'updatedAt'>
  ) }
);

export type CreateShopMutationVariables = Exact<{
  createShopInput?: Maybe<CreateShopInput>;
}>;


export type CreateShopMutation = (
  { __typename?: 'Mutation' }
  & { createShop?: Maybe<(
    { __typename?: 'Shop' }
    & Pick<Shop, 'guid' | 'name' | 'code' | 'createdAt' | 'updatedAt'>
  )> }
);

export type UpdateShopMutationVariables = Exact<{
  updateShopInput?: Maybe<UpdateShopInput>;
}>;


export type UpdateShopMutation = (
  { __typename?: 'Mutation' }
  & { updateShop?: Maybe<(
    { __typename?: 'Shop' }
    & Pick<Shop, 'guid' | 'name' | 'code' | 'createdAt' | 'updatedAt'>
  )> }
);

export type DeleteShopMutationVariables = Exact<{
  deleteShopInput?: Maybe<DeleteShopInput>;
}>;


export type DeleteShopMutation = (
  { __typename?: 'Mutation' }
  & { deleteShop?: Maybe<(
    { __typename?: 'Shop' }
    & Pick<Shop, 'guid' | 'name' | 'code' | 'createdAt' | 'updatedAt'>
  )> }
);

export type CardTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type CardTemplatesQuery = (
  { __typename?: 'Query' }
  & { cardTemplates?: Maybe<Array<Maybe<(
    { __typename?: 'CardTemplate' }
    & Pick<CardTemplate, 'guid' | 'name' | 'genre' | 'templateImageUrl' | 'isVisible' | 'defaultMessage' | 'createdAt' | 'updatedAt'>
    & { cardTemplateProperties?: Maybe<Array<Maybe<(
      { __typename?: 'CardTemplateProperty' }
      & Pick<CardTemplateProperty, 'guid' | 'name' | 'positionX' | 'positionY' | 'width' | 'height' | 'fontName' | 'fontSize' | 'fontColor' | 'bold' | 'align' | 'createdAt' | 'updatedAt'>
    )>>> }
  )>>> }
);

export type CardTemplateQueryVariables = Exact<{
  cardTemplateGuid: Scalars['ID'];
}>;


export type CardTemplateQuery = (
  { __typename?: 'Query' }
  & { cardTemplate?: Maybe<(
    { __typename?: 'CardTemplate' }
    & Pick<CardTemplate, 'guid' | 'name' | 'genre' | 'templateImageUrl' | 'isVisible' | 'defaultMessage' | 'createdAt' | 'updatedAt'>
    & { cardTemplateProperties?: Maybe<Array<Maybe<(
      { __typename?: 'CardTemplateProperty' }
      & Pick<CardTemplateProperty, 'guid' | 'name' | 'positionX' | 'positionY' | 'width' | 'height' | 'fontName' | 'fontSize' | 'fontColor' | 'bold' | 'align' | 'createdAt' | 'updatedAt'>
    )>>> }
  )> }
);

export type CardTemplatePropertiesQueryVariables = Exact<{ [key: string]: never; }>;


export type CardTemplatePropertiesQuery = (
  { __typename?: 'Query' }
  & { cardTemplateProperties?: Maybe<Array<Maybe<(
    { __typename?: 'CardTemplateProperty' }
    & Pick<CardTemplateProperty, 'guid' | 'name' | 'positionX' | 'positionY' | 'width' | 'height' | 'fontName' | 'fontSize' | 'fontColor' | 'bold' | 'align' | 'createdAt' | 'updatedAt'>
    & { cardTemplate: (
      { __typename?: 'CardTemplate' }
      & Pick<CardTemplate, 'guid'>
    ) }
  )>>> }
);

export type CardTemplatePropertyQueryVariables = Exact<{
  cardTemplatePropertyGuid: Scalars['ID'];
}>;


export type CardTemplatePropertyQuery = (
  { __typename?: 'Query' }
  & { cardTemplateProperty?: Maybe<(
    { __typename?: 'CardTemplateProperty' }
    & Pick<CardTemplateProperty, 'guid' | 'name' | 'positionX' | 'positionY' | 'width' | 'height' | 'fontName' | 'fontSize' | 'fontColor' | 'bold' | 'align' | 'createdAt' | 'updatedAt'>
    & { cardTemplate: (
      { __typename?: 'CardTemplate' }
      & Pick<CardTemplate, 'guid'>
    ) }
  )> }
);

export type NonFleshDaysQueryVariables = Exact<{ [key: string]: never; }>;


export type NonFleshDaysQuery = (
  { __typename?: 'Query' }
  & { nonFleshDays?: Maybe<Array<Maybe<(
    { __typename?: 'DeliveryCalendarDay' }
    & Pick<DeliveryCalendarDay, 'guid' | 'date' | 'type' | 'createdAt' | 'updatedAt'>
  )>>> }
);

export type NonBusinessDaysQueryVariables = Exact<{ [key: string]: never; }>;


export type NonBusinessDaysQuery = (
  { __typename?: 'Query' }
  & { nonBusinessDays?: Maybe<Array<Maybe<(
    { __typename?: 'DeliveryCalendarDay' }
    & Pick<DeliveryCalendarDay, 'guid' | 'date' | 'type' | 'createdAt' | 'updatedAt'>
  )>>> }
);

export type DeliveryCalendarDaysQueryVariables = Exact<{ [key: string]: never; }>;


export type DeliveryCalendarDaysQuery = (
  { __typename?: 'Query' }
  & { deliveryCalendarDays?: Maybe<Array<Maybe<(
    { __typename?: 'DeliveryCalendarDay' }
    & Pick<DeliveryCalendarDay, 'guid' | 'date' | 'type' | 'createdAt' | 'updatedAt'>
  )>>> }
);

export type DeliveryCalendarDayQueryVariables = Exact<{
  deliveryCalendarDayGuid?: Maybe<Scalars['String']>;
}>;


export type DeliveryCalendarDayQuery = (
  { __typename?: 'Query' }
  & { deliveryCalendarDay?: Maybe<(
    { __typename?: 'DeliveryCalendarDay' }
    & Pick<DeliveryCalendarDay, 'guid' | 'date' | 'type' | 'createdAt' | 'updatedAt'>
  )> }
);

export type InformationsQueryVariables = Exact<{ [key: string]: never; }>;


export type InformationsQuery = (
  { __typename?: 'Query' }
  & { informations?: Maybe<Array<Maybe<(
    { __typename?: 'Information' }
    & Pick<Information, 'guid' | 'title' | 'content' | 'publicationDate' | 'publicationEndDate' | 'createdAt' | 'updatedAt'>
  )>>> }
);

export type InformationQueryVariables = Exact<{
  guid: Scalars['ID'];
}>;


export type InformationQuery = (
  { __typename?: 'Query' }
  & { information?: Maybe<(
    { __typename?: 'Information' }
    & Pick<Information, 'guid' | 'title' | 'content' | 'publicationDate' | 'publicationEndDate' | 'createdAt' | 'updatedAt'>
  )> }
);

export type PickupStoresQueryVariables = Exact<{ [key: string]: never; }>;


export type PickupStoresQuery = (
  { __typename?: 'Query' }
  & { pickupStores?: Maybe<Array<Maybe<(
    { __typename?: 'PickupStore' }
    & Pick<PickupStore, 'guid' | 'name' | 'address' | 'createdAt' | 'updatedAt'>
  )>>> }
);

export type PickupStoreQueryVariables = Exact<{
  pickupStoreGuid?: Maybe<Scalars['String']>;
}>;


export type PickupStoreQuery = (
  { __typename?: 'Query' }
  & { pickupStore?: Maybe<(
    { __typename?: 'PickupStore' }
    & Pick<PickupStore, 'guid' | 'name' | 'address' | 'createdAt' | 'updatedAt'>
  )> }
);

export type ShopsQueryVariables = Exact<{ [key: string]: never; }>;


export type ShopsQuery = (
  { __typename?: 'Query' }
  & { shops?: Maybe<Array<Maybe<(
    { __typename?: 'Shop' }
    & Pick<Shop, 'guid' | 'name' | 'code' | 'updatedAt' | 'createdAt'>
  )>>> }
);

export type ShopQueryVariables = Exact<{
  shopCondition?: Maybe<ShopFindUniqueCondition>;
}>;


export type ShopQuery = (
  { __typename?: 'Query' }
  & { shop?: Maybe<(
    { __typename?: 'Shop' }
    & Pick<Shop, 'guid' | 'name' | 'code' | 'createdAt' | 'updatedAt'>
  )> }
);


export const CreateCardTemplateDocument = gql`
    mutation createCardTemplate($createCardTemplateInput: CreateCardTemplateInput) {
  createCardTemplate(input: $createCardTemplateInput) {
    guid
    name
    genre
    templateImageUrl
    isVisible
    defaultMessage
    createdAt
    updatedAt
    cardTemplateProperties {
      guid
      name
      positionX
      positionY
      width
      height
      fontName
      fontSize
      fontColor
      createdAt
      updatedAt
    }
  }
}
    `;
export const UpdateCardTemplateDocument = gql`
    mutation updateCardTemplate($updateCardTemplateInput: UpdateCardTemplateInput) {
  updateCardTemplate(input: $updateCardTemplateInput) {
    guid
    name
    genre
    templateImageUrl
    isVisible
    defaultMessage
    createdAt
    updatedAt
    cardTemplateProperties {
      guid
      name
      positionX
      positionY
      width
      height
      fontName
      fontSize
      fontColor
      createdAt
      updatedAt
    }
  }
}
    `;
export const DeleteCardTemplateDocument = gql`
    mutation deleteCardTemplate($deleteCardTemplateInput: DeleteCardTemplateInput) {
  deleteCardTemplate(input: $deleteCardTemplateInput) {
    guid
    name
    genre
    templateImageUrl
    defaultMessage
    isVisible
    createdAt
    updatedAt
  }
}
    `;
export const CreateCardTemplatePropertyDocument = gql`
    mutation createCardTemplateProperty($createCardTemplatePropertyInput: CreateCardTemplatePropertyInput) {
  createCardTemplateProperty(input: $createCardTemplatePropertyInput) {
    guid
    cardTemplate {
      guid
    }
    name
    positionX
    positionY
    width
    height
    fontName
    fontSize
    fontColor
    bold
    align
    createdAt
    updatedAt
  }
}
    `;
export const UpdateCardTemplatePropertyDocument = gql`
    mutation updateCardTemplateProperty($updateCardTemplatePropertyInput: UpdateCardTemplatePropertyInput) {
  updateCardTemplateProperty(input: $updateCardTemplatePropertyInput) {
    guid
    cardTemplate {
      guid
    }
    name
    positionX
    positionY
    width
    height
    fontName
    fontSize
    fontColor
    bold
    align
    createdAt
    updatedAt
  }
}
    `;
export const DeleteCardTemplatePropertyDocument = gql`
    mutation deleteCardTemplateProperty($deleteCardTemplatePropertyInput: DeleteCardTemplatePropertyInput) {
  deleteCardTemplateProperty(input: $deleteCardTemplatePropertyInput) {
    guid
    name
    positionX
    positionY
    width
    height
    fontName
    fontSize
    fontColor
    bold
    align
    createdAt
    updatedAt
  }
}
    `;
export const CreateDeliveryCalendarDayDocument = gql`
    mutation createDeliveryCalendarDay($createDeliveryCalendarDayInput: CreateDeliveryCalendarDayInput) {
  createDeliveryCalendarDay(input: $createDeliveryCalendarDayInput) {
    guid
    date
    type
    createdAt
    updatedAt
  }
}
    `;
export const UpdateDeliveryCalendarDayDocument = gql`
    mutation updateDeliveryCalendarDay($updateDeliveryCalendarDayInput: UpdateDeliveryCalendarDayInput) {
  updateDeliveryCalendarDay(input: $updateDeliveryCalendarDayInput) {
    guid
    date
    type
    createdAt
    updatedAt
  }
}
    `;
export const DeleteDeliveryCalendarDayDocument = gql`
    mutation deleteDeliveryCalendarDay($deleteDeliveryCalendarDayInput: DeleteDeliveryCalendarDayInput) {
  deleteDeliveryCalendarDay(input: $deleteDeliveryCalendarDayInput) {
    guid
    date
    type
    createdAt
    updatedAt
  }
}
    `;
export const CreateInformationDocument = gql`
    mutation createInformation($createInformationInput: CreateInformationInput) {
  createInformation(input: $createInformationInput) {
    guid
    title
    content
    publicationDate
    publicationEndDate
    createdAt
    updatedAt
  }
}
    `;
export const UpdateInformationDocument = gql`
    mutation updateInformation($updateInformationInput: UpdateInformationInput) {
  updateInformation(input: $updateInformationInput) {
    guid
    title
    content
    publicationDate
    publicationEndDate
    createdAt
    updatedAt
  }
}
    `;
export const DeleteInformationDocument = gql`
    mutation deleteInformation($deleteInformationInput: DeleteInformationInput) {
  deleteInformation(input: $deleteInformationInput) {
    guid
    title
    content
    publicationDate
    publicationEndDate
    createdAt
    updatedAt
  }
}
    `;
export const CreatePickupStoreDocument = gql`
    mutation createPickupStore($createPickupStoreInput: CreatePickupStoreInput) {
  createPickupStore(input: $createPickupStoreInput) {
    guid
    name
    address
    createdAt
    updatedAt
  }
}
    `;
export const UpdatePickupStoreDocument = gql`
    mutation updatePickupStore($updatePickupStoreInput: UpdatePickupStoreInput) {
  updatePickupStore(input: $updatePickupStoreInput) {
    guid
    name
    address
    createdAt
    updatedAt
  }
}
    `;
export const DeletePickupStoreDocument = gql`
    mutation deletePickupStore($deletePickupStoreInput: DeletePickupStoreInput) {
  deletePickupStore(input: $deletePickupStoreInput) {
    guid
    name
    address
    createdAt
    updatedAt
  }
}
    `;
export const CreateShopDocument = gql`
    mutation createShop($createShopInput: CreateShopInput) {
  createShop(input: $createShopInput) {
    guid
    name
    code
    createdAt
    updatedAt
  }
}
    `;
export const UpdateShopDocument = gql`
    mutation updateShop($updateShopInput: UpdateShopInput) {
  updateShop(input: $updateShopInput) {
    guid
    name
    code
    createdAt
    updatedAt
  }
}
    `;
export const DeleteShopDocument = gql`
    mutation deleteShop($deleteShopInput: DeleteShopInput) {
  deleteShop(input: $deleteShopInput) {
    guid
    name
    code
    createdAt
    updatedAt
  }
}
    `;
export const CardTemplatesDocument = gql`
    query cardTemplates {
  cardTemplates {
    guid
    name
    genre
    templateImageUrl
    isVisible
    defaultMessage
    createdAt
    updatedAt
    cardTemplateProperties {
      guid
      name
      positionX
      positionY
      width
      height
      fontName
      fontSize
      fontColor
      bold
      align
      createdAt
      updatedAt
    }
  }
}
    `;
export const CardTemplateDocument = gql`
    query cardTemplate($cardTemplateGuid: ID!) {
  cardTemplate(guid: $cardTemplateGuid) {
    guid
    name
    genre
    templateImageUrl
    isVisible
    defaultMessage
    createdAt
    updatedAt
    cardTemplateProperties {
      guid
      name
      positionX
      positionY
      width
      height
      fontName
      fontSize
      fontColor
      bold
      align
      createdAt
      updatedAt
    }
  }
}
    `;
export const CardTemplatePropertiesDocument = gql`
    query cardTemplateProperties {
  cardTemplateProperties {
    guid
    name
    positionX
    cardTemplate {
      guid
    }
    positionY
    width
    height
    fontName
    fontSize
    fontColor
    bold
    align
    createdAt
    updatedAt
  }
}
    `;
export const CardTemplatePropertyDocument = gql`
    query cardTemplateProperty($cardTemplatePropertyGuid: ID!) {
  cardTemplateProperty(guid: $cardTemplatePropertyGuid) {
    guid
    cardTemplate {
      guid
    }
    name
    positionX
    positionY
    width
    height
    fontName
    fontSize
    fontColor
    bold
    align
    createdAt
    updatedAt
  }
}
    `;
export const NonFleshDaysDocument = gql`
    query nonFleshDays {
  nonFleshDays {
    guid
    date
    type
    createdAt
    updatedAt
  }
}
    `;
export const NonBusinessDaysDocument = gql`
    query nonBusinessDays {
  nonBusinessDays {
    guid
    date
    type
    createdAt
    updatedAt
  }
}
    `;
export const DeliveryCalendarDaysDocument = gql`
    query deliveryCalendarDays {
  deliveryCalendarDays {
    guid
    date
    type
    createdAt
    updatedAt
  }
}
    `;
export const DeliveryCalendarDayDocument = gql`
    query deliveryCalendarDay($deliveryCalendarDayGuid: String) {
  deliveryCalendarDay(guid: $deliveryCalendarDayGuid) {
    guid
    date
    type
    createdAt
    updatedAt
  }
}
    `;
export const InformationsDocument = gql`
    query informations {
  informations {
    guid
    title
    content
    publicationDate
    publicationEndDate
    createdAt
    updatedAt
  }
}
    `;
export const InformationDocument = gql`
    query information($guid: ID!) {
  information(guid: $guid) {
    guid
    title
    content
    publicationDate
    publicationEndDate
    createdAt
    updatedAt
  }
}
    `;
export const PickupStoresDocument = gql`
    query pickupStores {
  pickupStores {
    guid
    name
    address
    createdAt
    updatedAt
  }
}
    `;
export const PickupStoreDocument = gql`
    query pickupStore($pickupStoreGuid: String) {
  pickupStore(guid: $pickupStoreGuid) {
    guid
    name
    address
    createdAt
    updatedAt
  }
}
    `;
export const ShopsDocument = gql`
    query shops {
  shops {
    guid
    name
    code
    updatedAt
    createdAt
  }
}
    `;
export const ShopDocument = gql`
    query shop($shopCondition: ShopFindUniqueCondition) {
  shop(uniqueCondition: $shopCondition) {
    guid
    name
    code
    createdAt
    updatedAt
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    createCardTemplate(variables?: CreateCardTemplateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateCardTemplateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateCardTemplateMutation>(CreateCardTemplateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createCardTemplate');
    },
    updateCardTemplate(variables?: UpdateCardTemplateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateCardTemplateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateCardTemplateMutation>(UpdateCardTemplateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateCardTemplate');
    },
    deleteCardTemplate(variables?: DeleteCardTemplateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteCardTemplateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteCardTemplateMutation>(DeleteCardTemplateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteCardTemplate');
    },
    createCardTemplateProperty(variables?: CreateCardTemplatePropertyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateCardTemplatePropertyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateCardTemplatePropertyMutation>(CreateCardTemplatePropertyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createCardTemplateProperty');
    },
    updateCardTemplateProperty(variables?: UpdateCardTemplatePropertyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateCardTemplatePropertyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateCardTemplatePropertyMutation>(UpdateCardTemplatePropertyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateCardTemplateProperty');
    },
    deleteCardTemplateProperty(variables?: DeleteCardTemplatePropertyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteCardTemplatePropertyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteCardTemplatePropertyMutation>(DeleteCardTemplatePropertyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteCardTemplateProperty');
    },
    createDeliveryCalendarDay(variables?: CreateDeliveryCalendarDayMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateDeliveryCalendarDayMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateDeliveryCalendarDayMutation>(CreateDeliveryCalendarDayDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createDeliveryCalendarDay');
    },
    updateDeliveryCalendarDay(variables?: UpdateDeliveryCalendarDayMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateDeliveryCalendarDayMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateDeliveryCalendarDayMutation>(UpdateDeliveryCalendarDayDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateDeliveryCalendarDay');
    },
    deleteDeliveryCalendarDay(variables?: DeleteDeliveryCalendarDayMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteDeliveryCalendarDayMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteDeliveryCalendarDayMutation>(DeleteDeliveryCalendarDayDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteDeliveryCalendarDay');
    },
    createInformation(variables?: CreateInformationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateInformationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateInformationMutation>(CreateInformationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createInformation');
    },
    updateInformation(variables?: UpdateInformationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateInformationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateInformationMutation>(UpdateInformationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateInformation');
    },
    deleteInformation(variables?: DeleteInformationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteInformationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteInformationMutation>(DeleteInformationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteInformation');
    },
    createPickupStore(variables?: CreatePickupStoreMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreatePickupStoreMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreatePickupStoreMutation>(CreatePickupStoreDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createPickupStore');
    },
    updatePickupStore(variables?: UpdatePickupStoreMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdatePickupStoreMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdatePickupStoreMutation>(UpdatePickupStoreDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updatePickupStore');
    },
    deletePickupStore(variables?: DeletePickupStoreMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeletePickupStoreMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeletePickupStoreMutation>(DeletePickupStoreDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deletePickupStore');
    },
    createShop(variables?: CreateShopMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateShopMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateShopMutation>(CreateShopDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createShop');
    },
    updateShop(variables?: UpdateShopMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateShopMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateShopMutation>(UpdateShopDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateShop');
    },
    deleteShop(variables?: DeleteShopMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteShopMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteShopMutation>(DeleteShopDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteShop');
    },
    cardTemplates(variables?: CardTemplatesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CardTemplatesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CardTemplatesQuery>(CardTemplatesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'cardTemplates');
    },
    cardTemplate(variables: CardTemplateQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CardTemplateQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CardTemplateQuery>(CardTemplateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'cardTemplate');
    },
    cardTemplateProperties(variables?: CardTemplatePropertiesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CardTemplatePropertiesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CardTemplatePropertiesQuery>(CardTemplatePropertiesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'cardTemplateProperties');
    },
    cardTemplateProperty(variables: CardTemplatePropertyQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CardTemplatePropertyQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CardTemplatePropertyQuery>(CardTemplatePropertyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'cardTemplateProperty');
    },
    nonFleshDays(variables?: NonFleshDaysQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<NonFleshDaysQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<NonFleshDaysQuery>(NonFleshDaysDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'nonFleshDays');
    },
    nonBusinessDays(variables?: NonBusinessDaysQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<NonBusinessDaysQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<NonBusinessDaysQuery>(NonBusinessDaysDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'nonBusinessDays');
    },
    deliveryCalendarDays(variables?: DeliveryCalendarDaysQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeliveryCalendarDaysQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeliveryCalendarDaysQuery>(DeliveryCalendarDaysDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deliveryCalendarDays');
    },
    deliveryCalendarDay(variables?: DeliveryCalendarDayQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeliveryCalendarDayQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeliveryCalendarDayQuery>(DeliveryCalendarDayDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deliveryCalendarDay');
    },
    informations(variables?: InformationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<InformationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<InformationsQuery>(InformationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'informations');
    },
    information(variables: InformationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<InformationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<InformationQuery>(InformationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'information');
    },
    pickupStores(variables?: PickupStoresQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PickupStoresQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PickupStoresQuery>(PickupStoresDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'pickupStores');
    },
    pickupStore(variables?: PickupStoreQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PickupStoreQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PickupStoreQuery>(PickupStoreDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'pickupStore');
    },
    shops(variables?: ShopsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ShopsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ShopsQuery>(ShopsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'shops');
    },
    shop(variables?: ShopQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ShopQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ShopQuery>(ShopDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'shop');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;